<template>
  <span>
    <v-dialog v-if="dialogDetalhes" v-model="dialogDetalhes" :scrollable="true">
      <v-card>
        <v-card-title>
          <h5>Detalhes Visão Geral</h5>
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-expansion-panels accordion>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Novos Alimentadores ({{ dadosNovosCodigos.al.length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    readonly
                    :value="dadosNovosCodigos.al.join('\n')"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Novas Subestações ({{ dadosNovosCodigos.se.length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    readonly
                    :value="dadosNovosCodigos.se.join('\n')"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Novas Linhas de Distribuição ({{ dadosNovosCodigos.ld.length }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    readonly
                    :value="dadosNovosCodigos.ld.join('\n')"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6">
          <v-spacer />
          <v-btn
            text
            color="primary"
            class="text-h2 pa-12"
            @click="dialogDetalhes = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
  import PainelTecnicosService from "@/services/PainelTecnicosService.js";

  export default {
    name: "TecnicosDashboardDialogDetalhes",
    props: {
      competencia: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      dialogDetalhes: false,
      dadosNovosCodigos: {},
    }),
    methods: {
      getDadosNovosCodigos(competencia = this.competencia) {
        PainelTecnicosService.getNovosCodigosByCompetencia(competencia)
          .then((res) => {
            this.dadosNovosCodigos = res;

            this.dialogDetalhes = true;
          })
          .catch((err) => {
            console.error(err);
          });
      },
    },
  };
</script>

<style></style>
